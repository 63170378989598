#about {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
}

#about-spacer {
    height: 3rem;
}

h2 {
    font-size: 3rem;
}

.about__container {
    display: flex;
    justify-content: center;
}

.about__me {
    max-width: 50%;
    border-radius: 2rem;
    margin-right: 2rem;
    
}

.about__me__image img {
    border-radius: 2rem;
    
}

.about__content {
    max-width: 50%;
}

.about__bio {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 2rem;
}




/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */

@media only screen and (max-width: 1024px) {
    #about {
        height: fit-content;
    }
  
    .about__container {
    flex-direction: column;
    align-items: center;
  }

  .about__me {
    margin-bottom: 2rem;
    margin-right: 0;
    
}


}

@media only screen and (min-height: 1000px) {
    .about__bio p {
        font-size: 1.5rem;
    }

    .btn {
        font-size: 2rem;
    }
}