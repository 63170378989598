header {
    height: 100vh;
    width: 100vw;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
}

.header__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
}

.title h5 {
    font-size: 1.5rem;
}

.title h1 {
    font-size: 2.5rem;
}

/* CTA STYLING */

.cta {
    /* margin-top: 2.5rem; */
    display: flex;
    justify-content: center;
    gap: 1rem;
}

/* HEADERSOCIALS STYLING */

.header__socials {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.8rem;
    font-size: 3rem;
    margin-bottom: 1rem;
}

/*MAIN IMAGE*/

.me {
    margin-top: 3rem;
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    border-radius: 5rem;
    top: 11rem;
}

/*jump to contact style*/

.jump__contact {
    position: flex;
    top: 75%;
    left: calc(50% - 4rem);
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */

@media screen and (max-width: 1024px) {
  

    /* .header__socials {
        display: flex;
        flex-direction: row;
        position: absolute;
        align-items: center;
        left: calc(50% - 3rem);
    } */


}

/* ======== MEDIA QUERIES (SMALL DEVICES) ======== */

@media screen and (max-width: 600px) {
    

    /* .header__socials {
        display: flex;
        flex-direction: row;
        position: absolute;
        align-items: center;
        left: calc(50% - 3rem);
    } */

}
