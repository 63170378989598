nav {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  display: block;
  z-index: 2;
  width: max-content;
  padding: 0.7rem 1.7rem;
  transform: translateX(-50%);
  left: 50%;
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

nav a {
  background: transparent;
  padding: 0.3rem;
  border-radius: 10%;
  display: flex;
  color: var(--color-white);
  font-size: 2rem;
}

nav a:hover {
  color: var(--color-primary);
}

/*nav a.active denotes active class - on anchor inside nav, styles the active element*/
nav a.active {
  background: var(--color-primary-variant);
  color: var(--color-white);
}

.hover__span {
   display: hidden
}

@media only screen and (min-height: 1000px) {
  nav {
    font-size: 2rem;
  }
}

@media only screen and (orientation: landscape) and (max-height: 420px) {
  nav {
    flex-direction: column;
    left: 5%;
    padding: 0.5rem 0.3rem;
  }
}
