h3 {
    margin-bottom: 2rem;
}

#portfolio {
    height:fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.portfolio__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center; 
    align-items: center;
    gap: 2rem;
    height: fit-content
}

.data__projects {
    margin-bottom: 4rem;
}

.portfolio__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-bg-variant);
    padding: 1rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: 40rem;
    width: 30%;
    box-sizing: border-box;
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__item-img {
    border-radius: 1.5rem;
    overflow: hidden;
    height: 60%;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.project__description {
    text-align: center;
    margin-bottom: 1rem;
}

.tech {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.tech__item {
    margin: 0 1rem 1rem 0;
    font-weight: 700;
}

/* ======== MEDIA QUERIES (MEDIUM DEVICES) ======== */

@media screen and (max-width: 1024px) {
 .portfolio__container {
    flex-direction: column;
 }

 .portfolio__item {
    width: 100%;
 }
   
}
   
   /* ======== MEDIA QUERIES (SMALL DEVICES) ======== */
   
   @media screen and (max-width: 600px) {
   
      
}